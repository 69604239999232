<template>
    <div class="testReports" :style="testReports">
        <common-nav-bar title="我的测试报告"></common-nav-bar>
        <div class="instrumentCard" v-if='curSn'>
            <div class="cardTile cell myBound">
                <div class="cardTileLeft">ID</div>
                <div class="cardTileRight cellFlex-1 textL">{{ curSn }}</div>
            </div>
            <div class="cardBody">
                <div class="userInfo">
                    <van-cell-group inset>
                        <van-cell class="bgNo" center>
                            <template #icon>
                                <van-image
                                    :src="curSnUser.patientWxHeadimgurl || userPhoto"
                                    width="35"
                                />
                            </template>
                            <template #title>
                                <span class="userName mlr10">{{ curSnUser.patientName }}</span>
                                <van-icon
                                    :name="curSnUser.patientSex === 1 ? sexNan : sexNv"
                                    class="sex-icon"
                                />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </div>
            </div>
        </div>
        <van-cell
            title-class="reportsTip"
            icon="volume-o"
            title=""
            label="提示：目前仅提供68波段测试报告。"
        />
        <div class="reports">
            <van-row class="reportsTitle textC">
                <van-col span="8">
                    <van-cell center>
                        <template #title>
                            <span>报告日期</span>
                        </template>
                    </van-cell>
                    <van-calendar v-model="show" type="multiple" color="#4AB8AB" />
                </van-col>
                <van-col span="8">服务中心</van-col>
                <van-col span="8">获取报告</van-col>
            </van-row>
            <van-list>
                <van-row v-for="(item, i) in seRecords" :key="i" class="reportsList textC">
                    <van-col span="8">{{ item.createTime }}</van-col>
                    <van-col span="8">{{ item.clinic }}</van-col>
                    <van-col span="8" @click="toDetailPage(item.id)"
                        ><span class="tplCopy">点击查看</span></van-col
                    >
                </van-row>
            </van-list>
        </div>
    </div>
</template>

<script>
import { getSeRecordList } from '@/services/se_record';
import { toast,formatDate } from '@/util';
import CommonNavBar from '@/components/common-nav-bar';
export default {
    // name: "index"
    components:{CommonNavBar},
    data() {
        return {
            testReports: {
                backgroundImage: 'url(' + require('@/assets/images/myBgReverse.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                minHeight: '100%',
                backgroundAttachment: 'fixed',
                padding: '10px',
            },
            list: [],
            loading: false,
            finished: false,
            iconDown: require('@/assets/images/iconDown.png'),
            show: false,

            curSn: '',
            curSnUser: {},
            isMember: true,
            userPhoto: require('@/assets/images/userPhoto.png'),
            userDefault: require('@/assets/images/userDefault.png'),
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),

            seRecords: [],
        };
    },
    async created() {
        this.curSn = localStorage.getItem('curSn');
        if (this.curSn) {
            this.curSnUser = JSON.parse(localStorage.getItem('curSnUser'));
        }
        await this.getSeRecordList();
    },
    methods: {
        async getSeRecordList() {
            const { code, message, data } = await getSeRecordList(
                this.curSnUser ? this.curSnUser.patientUserNo : ''
            );
            if (!code) {
                return toast(message);
            }
            data.forEach(d => {
                d.createTime = formatDate(d.createTime, 'yyyy-MM-dd');
            });
            this.seRecords = data;
        },
        toDetailPage(id) {
            this.$router.push({ path: '/patient/my/testReportDetail', query: { id } });
        },
    },
};
</script>

<style scoped>
.testReports {
    box-sizing: border-box;
}
.testReports .van-cell::after {
    border-color: #ccc;
}
.reportsTitle .van-cell::after {
    border: none;
}
.reportsTip {
    font-size: 10px;
    color: #a3a3a3;
}
.reports {
    padding-top: 40px;
    position: relative;
    overflow-y: auto;
    height: calc(100% - 239px);
}
.reportsTitle {
    font-size: 14px;
    color: #282a2d;
    line-height: 40px;
    background: #fff;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 2;
    box-shadow: 0 2px 5px #ccc;
    font-weight: 600;
}
.reportsTitle .van-cell {
    padding: 0;
    font-size: 14px;
    color: #282a2d;
    line-height: 40px;
}
.reportsTitle .van-cell .van-image {
    margin-left: 5px;
    position: relative;
    top: -3px;
}
.reportsList {
    font-size: 14px;
    color: #6d7278;
    line-height: 35px;
    /* background: rgba(255,255,255,.7); */
    padding: 5px 0;
    position: relative;
}
.reportsList ::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    border-bottom: 1px solid #ccc;
    display: block;
}
.reportsList:last-child {
    border-bottom: 0;
}
.tplCopy {
    color: #4ab8ab;
    text-decoration: underline;
    display: inline-block;
    padding: 0 15px;
    font-size: 10px;
}

.instrumentCard {
    background: #fff;
    overflow: hidden;
    border-radius: 8px;
}
.cardTile {
    box-sizing: border-box;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

.cardTile::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: #4ab8ab;
    width: 90%;
    left: 5%;
    bottom: 0;
}

.myBound.cardTile::after {
    background: #bebebe;
}

.cardTileLeft {
    position: relative;
    color: #acacac;
    font-weight: bold;
}

.cardTileLeft::before {
    content: '';
    display: block;
    position: absolute;
    left: -16px;
    top: 3px;
    width: 5px;
    height: 20px;
    background: #4ab8ab;
}

.cardTileRight {
    font-size: 14px;
    font-weight: 600;
    color: #282a2d;
    text-align: left;
    padding-left: 10px;
}

.cardBody .cardBodyLeft {
    font-size: 24px;
    color: #8d8d8d;
    font-weight: 600;
}

.cardBody .cardBodyRight {
    font-size: 24px;
    color: #282a2d;
    text-align: center;
}

.userInfo {
    padding: 10px;
}
.userInfo .van-cell__left-icon {
    font-size: 35px;
    height: auto;
    line-height: 100%;
}
.userName {
    font-size: 16px;
    color: #282a2d;
}
.sex-icon {
    font-size: 16px;
    vertical-align: middle;
}

.userInfo .van-cell-group {
    background: #f7f7f7;
    margin: 0;
}
</style>
